import request from "@/utils/request"
let headers = {
    'language': localStorage.getItem("language") || "zh-CN"
}
// 初始化套餐
export function getinituser() {
    return request({
        method: 'post',
        url: '/api/pay/init',
        headers: headers,

    })
}

// 创建订单
export function createOrder(params = {}) {
    return request({
        method: 'post',
        url: '/api/order/create',
        data: params,
        headers: headers,

    })
}

export function paypalreturn(params = {}) {
    return request({
        method: 'post',
        url: '/api/pay/paypal/return',
        data: params,
        headers: headers

    })
}
export function checkreturn(params = {}) {
    return request({
        method: 'post',
        url: '/api/check/return',
        data: params,
        headers: headers,


    })
}
export function checkcancel(params = {}) {
    return request({
        method: 'post',
        url: '/api/check/cancel',
        data: params,
        headers: headers,


    })
}
export function paycancel(params = {}) {
    return request({
        method: 'post',
        url: '/api/pay/cancel',
        data: params,
        headers: headers

    })
}