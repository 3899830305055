<template>
  <div class="memberCenter">
    <div class="user_con">
      <LeftMember :userData="userData" />
      <div class="right_payment_method">
        <h3 class="payment_tit">
          {{ $t("lang.memberCenter.RenewMembership") }}
        </h3>
        <h3 class="payment_tit_app">
          {{ $t("lang.memberCenter.Member_Price") }}
        </h3>
        <p class="expire_days">
          {{ $t("lang.memberCenter.Validity") }}{{ $t("lang.common.surplus")
          }}<span> {{ days }} </span>{{ $t("lang.common.days") }}
        </p>
        <div class="renew_list">
          <div
            class="recommend_pay"
            :class="recommend_active_flag == '' ? 'unselected_recommend' : ''"
            v-if="userData.level"
          >
            <img
              v-if="recommend_active_flag"
              class="bcg_img"
              src="@/assets/image/memberCenter/bg_Price.png"
              alt=""
            />
            <img
              v-else
              class="bcg_img"
              src="@/assets/image/memberCenter/bg_Price-yellow.png"
              alt=""
            />
            <p class="respect">
              <img src="@/assets/image/memberCenter/icon_gift.png" alt="" />
              {{ $t("lang.memberCenter.VIP") }}{{ userData.level }}
              {{ $t("lang.memberCenter.VIP2") }}
              {{
                userData.level == 1
                  ? "58%"
                  : userData.level == 2
                  ? "62%"
                  : "65%"
              }}
              {{ $t("lang.memberCenter.VIP3") }}
            </p>
            <div class="package_price" @click="selectRecommend">
              <div class="left_details">
                <p class="size12">
                  {{ $t("lang.memberCenter.low_price") }}${{
                    recommend_item[0].cycleAveragePrice
                  }}/{{ $t("lang.common.day") }}
                </p>
                <h3 class="type_package">
                  {{ $t("lang.memberCenter.noe_Month_VIP") }}
                </h3>
                <p class="Member_Description">
                  {{ $t("lang.memberCenter.old_user") }}{{ userData.level }}
                  {{ $t("lang.memberCenter.old_user2") }}
                </p>
              </div>
              <div class="right_price">
                <p class="price_text">
                  $<span class="money">{{
                    recommend_item[0].cycleRecommendPrice
                  }}</span>
                </p>
                <p class="Preferential_strength">
                  {{ $t("lang.memberCenter.Save") }}

                  {{
                    userData.level == 1
                      ? "58%"
                      : userData.level == 2
                      ? "62%"
                      : "65%"
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="row price_ul">
            <div class="col-sm-3 col_li" v-for="(item, i) in cycle" :key="i">
              <div
                class="card price_li"
                :class="active_item.cycleId == item.cycleId ? 'focus' : ''"
                :id="item.cycleIsRecommend == '1' ? 'recommend_active' : ''"
                @click="setmealChange(item)"
              >
                <!-- <span
                class="recommend_tit"
                v-if="item.cycleIsRecommend == '1' ? 'recommend_active' : ''"
                >{{ $t("lang.memberCenter.recommend") }}</span
              > -->
                <span class="share_top" v-if="userData.totalDays <= 0">
                  {{ $t("lang.memberCenter.Share_Coupon") }}</span
                >

                <div class="card-body">
                  <h5 class="card-title">
                    {{ item.payCycle }}
                    <!-- {{ item.payCycleUnit }} -->
                    <span
                      v-if="
                        $route.query.language == 'zh' || $i18n.locale == 'zh-CN'
                      "
                      >{{
                        item.payCycleUnit == "day" || item.payCycleUnit == "天"
                          ? "天"
                          : item.payCycleUnit == "Months" ||
                            item.payCycleUnit == "月"
                          ? "個月"
                          : item.payCycleUnit == "Month" ||
                            item.payCycleUnit == "月"
                          ? "個月"
                          : ""
                      }}</span
                    >
                    <span v-else>
                      {{
                        item.payCycleUnit == "day" || item.payCycleUnit == "天"
                          ? "day"
                          : item.payCycleUnit == "Months" ||
                            item.payCycleUnit == "月"
                          ? "Months"
                          : item.payCycleUnit == "Month" ||
                            item.payCycleUnit == "月"
                          ? "Month"
                          : ""
                      }}
                    </span>
                  </h5>

                  <!-- <div class="card-text">
                  <span v-if="$i18n.locale == 'zh-CN'">$</span>
                  <h1 class="pic_h1">{{ item.cycleAveragePrice }}</h1>
                  <span v-if="$i18n.locale == 'zh-CN'"
                    >/{{
                      item.payCycleUnit == "天" || item.payCycleUnit == "day"
                        ? $t("lang.common.day")
                        : $t("lang.common.month")
                    }}</span
                  >
                  <p v-if="$i18n.locale == 'en-US'" class="preday">
                    {{ $t("lang.Price.premonth") }}
                  </p>
                </div> -->
                  <!-- <p class="only_need">
                  {{ $t("lang.Price.just") }}${{ item.cycleTotalPrice }}
                </p> -->
                  <p class="only_need">
                    $<span>{{ item.cycleTotalPrice }}</span>
                  </p>
                  <p class="economize" v-if="item.cycleDiscountPrice != 0">
                    <span>
                      {{ $t("lang.Price.save") }}
                      {{ item.cycleDiscount }}%</span
                    >
                  </p>

                  <!-- v-if="item.payCycle != 7" -->
                  <!-- <div class="seven_text">
                  <p :class="item.payCycle != 7 ? '' : 'block_p'">
                    <img
                      src="@/assets/image/memberCenter/grade/icon-price-7days.png"
                      alt=""
                    />
                    <span>{{ $t("lang.Price.guarantee") }}</span>
                  </p>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="share" v-if="userData.totalDays <= 0">
          <img src="@/assets/image/memberCenter/icon_gift.png" alt="" />
          {{ $t("lang.memberCenter.give_coupon") }}
        </p>
        <h3 class="payment_tit_pay">
          {{ $t("lang.memberCenter.Total_order_price") }}
        </h3>
        <div class="pay_price">
          <p class="price_text">
            ${{
              userData.totalDays > 0 && recommend_active_flag != ""
                ? recommend_active_flag.cycleRecommendPrice
                : active_item.cycleTotalPrice
            }}
          </p>
          <p class="pay_btn" @click="paymentPop">
            {{ $t("lang.memberCenter.payment") }}
          </p>
        </div>
      </div>
    </div>
    <!-- 移动端--底部支付按钮--开始 -->
    <div class="sub_fot">
      <p class="seven_days">
        <img
          src="@/assets/image/memberCenter/grade/icon-price-7days.png"
          alt=""
        />
        {{ $t("lang.Price.guarantee") }}
      </p>
      <div class="btn_style">
        <div class="fot_pay_bcg">
          <div class="orders_submitted">
            <p class="orders_money">
              <span
                class="money"
                v-if="active_item != '' || recommend_active_flag != ''"
                >${{
                  userData.totalDays > 0 && recommend_active_flag != ""
                    ? recommend_active_flag.cycleRecommendPrice
                    : active_item.cycleTotalPrice
                }}</span
              >

              <span class="right_subscription">{{
                $t("lang.memberCenter.Subscribe_VIP")
              }}</span>
            </p>
            <!-- {{ recommend_active_flag }}
              {{ active_item }} -->
          </div>
          <div class="orders_submitted_app">
            <p v-if="active_item.payCycle != 7">
              <img
                src="@/assets/image/memberCenter/grade/icon-price-7days.png"
                alt=""
              />{{ $t("lang.Price.guarantee") }}
            </p>
            <p v-else></p>
          </div>
          <button
            type="button"
            class="btn submit_btn btn-warning"
            :class="active_item.cycleTotalPrice && payType ? '' : 'btn_none'"
            @click="paymentPop"
          >
            <span class="btn_money" v-if="active_item != ''"
              >${{
                userData.totalDays > 0 && recommend_active_flag != ""
                  ? recommend_active_flag.cycleRecommendPrice
                  : active_item.cycleTotalPrice
              }}</span
            >
            {{ $t("lang.memberCenter.payment") }}
          </button>
        </div>
      </div>
    </div>
    <!-- 移动端--底部支付按钮--结束 -->

    <!-- 支付方式----弹框--开始 -->
    <div
      class="mode_payment_pop"
      v-loading="initloading"
      v-if="mode_payment_pop_flag"
    >
      <div class="mode" @click="closeModePay"></div>
      <div class="mode_pay_content">
        <div class="close" @click="closeModePay">
          <i class="el-icon-close"></i>
        </div>
        <h3 class="payment_tit_pay">
          {{ $t("lang.memberCenter.paymentMethod") }}
        </h3>
        <div class="mode_payment">
          <!-- 信用卡 -->
          <div class="payment_list">
            <label
              class="form-check-label"
              for="inlineRadio0"
              style="width: 100%"
            >
              <div
                class="credit_card"
                :class="payType == 'other' ? 'active_pay' : ''"
                v-if="cardType"
              >
                <div
                  class="form-check form-check-inline app_block"
                  @click="getpayment(cardType)"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio0"
                    value="credit_card"
                    checked
                  />
                  <span class="Unionpay" v-if="$i18n.locale == 'zh-CN'">{{
                    cardType.wayNameZh
                  }}</span>
                  <span class="Unionpay" v-if="$i18n.locale == 'en-US'">{{
                    cardType.wayNameEn
                  }}</span>
                </div>
                <img
                  src="@/assets/image/memberCenter/Local_Payment.png"
                  alt=""
                />
              </div>
            </label>

            <!-- 支付宝，贝宝之类的支付方式 -->
            <div class="row">
              <div
                class="col-sm-4 form-check form-check-inline app_block"
                v-for="(item, i) in way"
                :key="i"
              >
                <label
                  class="form-check-label"
                  :for="'inlineRadio' + (i + 1)"
                  style="width: 100%"
                >
                  <div
                    class="pay_list"
                    :class="payType == item.wayType ? 'active_pay' : ''"
                  >
                    <div @click="getpayment(item)" class="pay_list_tit">
                      <!-- <span class="Unionpay Alipay">{{ item.wayNameZh }}</span> -->
                      <span
                        class="Unionpay Alipay"
                        v-if="$i18n.locale == 'zh-CN'"
                        >{{ item.wayNameZh }}</span
                      >
                      <span
                        class="Unionpay Alipay"
                        v-if="$i18n.locale == 'en-US'"
                        >{{ item.wayNameEn }}</span
                      >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        :id="'inlineRadio' + (i + 1)"
                        value="option2"
                      />
                    </div>
                    <!-- <img src="@/assets/image/memberCenter/Unionpay.png" alt="" /> -->
                    <img
                      v-if="item.wayType == 'alipay'"
                      src="@/assets/image/memberCenter/Alipay.png"
                      alt=""
                    />
                    <img
                      v-if="item.wayType == 'paypal'"
                      src="@/assets/image/memberCenter/paypal.png"
                      alt=""
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="sub_fot">
            <p class="seven_days">
              <img
                src="@/assets/image/memberCenter/grade/icon-price-7days.png"
                alt=""
              />
              {{ $t("lang.Price.guarantee") }}
            </p>
            <div class="btn_style">
              <div class="fot_pay_bcg">
                <div class="orders_submitted">
                  <p class="orders_money">
                    <span
                      class="money"
                      v-if="active_item != '' || recommend_active_flag != ''"
                      >${{
                        userData.totalDays > 0 && recommend_active_flag != ""
                          ? recommend_active_flag.cycleRecommendPrice
                          : active_item.cycleTotalPrice
                      }}</span
                    >
                    <span class="right_subscription">{{
                      $t("lang.memberCenter.Subscribe_VIP")
                    }}</span>
                  </p>
                </div>
                <div class="orders_submitted_app">
                  <p v-if="active_item.payCycle != 7">
                    <img
                      src="@/assets/image/memberCenter/grade/icon-price-7days.png"
                      alt=""
                    />{{ $t("lang.Price.guarantee") }}
                  </p>
                  <p v-else></p>
                </div>
                <button
                  type="button"
                  class="btn submit_btn btn-warning"
                  :class="
                    active_item.cycleTotalPrice && payType ? '' : 'btn_none'
                  "
                  @click="submitOrder"
                >
                  <span
                    class="btn_money"
                    v-if="active_item != '' || recommend_active_flag != ''"
                    >${{
                      userData.totalDays > 0 && recommend_active_flag != ""
                        ? recommend_active_flag.cycleRecommendPrice
                        : active_item.cycleTotalPrice
                    }}</span
                  >
                  {{ $t("lang.memberCenter.payment") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付方式----弹框--结束 -->
    <div
      class="unpaid_pop"
      v-if="unpaid_Flag && oldpayData"
      v-loading="initloading"
    >
      <div class="unpaid_content">
        <h3>{{ $t("lang.memberCenter.unpaid") }}</h3>
        <p class="set_meal">
          {{ oldpayData.days }}
          {{ $t("lang.memberCenter.oldpayDataDay") }}
        </p>
        <p class="money">${{ oldpayData.amount }}</p>
        <div class="sub_btn">
          <button class="cancal" @click="paycancel">
            {{ $t("lang.memberCenter.Cancel") }}
          </button>
          <button class="paysub" @click="payagain">
            {{ $t("lang.memberCenter.Payment") }}
          </button>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="experienceCardFlag"
      width="80%"
      :show-close="false"
      center
      class="home_share_dialog"
    >
      <div>
        <!-- <span>免費體驗時長 24 小时</span> -->
        <div class="card_box">
          <div class="card_pic">
            <img
              class="bg-experience pc_block"
              src="@/assets/image/memberCenter/grade/Coupon_bg.png"
              alt=""
            />
            <div class="share_tit">
              <h1>{{ $t("lang.memberCenter.Congratulations") }}</h1>
              <!-- <p>恭喜您獲得分享券 <span>2</span> 張</p> -->
              <p>
                {{ $t("lang.memberCenter.Get_share") }}
                <span v-if="$i18n.locale == 'zh-CN'">2</span>
                {{ $t("lang.memberCenter.zhang") }}
              </p>
              <p>{{ $t("lang.memberCenter.share_look") }}</p>
            </div>
            <div class="Coupon_box">
              <img
                class="coupon_pic"
                src="@/assets/image/memberCenter/grade/Coupon.png"
                alt=""
              />
              <span class="coupon_day">
                <span>15</span>{{ $t("lang.common.day") }}
              </span>
              <div class="my_coupon">
                <p class="size14">
                  {{ $t("lang.memberCenter.My_VIP_Share") }}
                </p>
                <p class="size12">{{ $t("lang.memberCenter.give_friends") }}</p>
              </div>
              <span
                class="share_btn"
                :data-clipboard-text="
                  'http://192.168.31.249:8081/#/?shareEmail=' +
                  userData.userEmail +
                  '&shareUserName=' +
                  userData.userName
                "
                @click="copyShare"
                >{{ $t("lang.memberCenter.Share") }}</span
              >
            </div>
            <div class="Coupon_box Coupon_box2">
              <img
                class="coupon_pic"
                src="@/assets/image/memberCenter/grade/Coupon.png"
                alt=""
              />
              <span class="coupon_day">
                <span>15</span>
                {{ $t("lang.common.day") }}
              </span>
              <div class="my_coupon">
                <p class="size14">{{ $t("lang.memberCenter.My_VIP_Share") }}</p>
                <p class="size12">{{ $t("lang.memberCenter.give_friends") }}</p>
              </div>
              <span
                class="share_btn"
                :data-clipboard-text="
                  'http://192.168.31.249:8081/#/?shareEmail=' +
                  userData.userEmail +
                  '&shareUserName=' +
                  userData.userName
                "
                @click="copyShare"
                >{{ $t("lang.memberCenter.Share") }}</span
              >
            </div>
            <div class="close">
              <img
                class="bg-experience pc_block"
                src="@/assets/image/memberCenter/grade/serve_close.png"
                alt=""
                @click="experienceCardFlag = false"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer"> </span> -->
    </el-dialog>
    <el-dialog
      title=""
      class="Level_Description_dialog"
      :visible.sync="Level_Description_flag"
      :before-close="handleClose"
    >
      <div class="Level_Description_box">
        <img src="@/assets/image/memberCenter/Level_Description.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LeftMember from "@/components/LeftMember.vue";
import { loadAirwallex, redirectToCheckout } from "airwallex-payment-elements";
import Clipboard from "clipboard";
export default {
  components: { LeftMember },
  data() {
    return {
      userData: {},
      cycle: [],
      way: [],
      unionpay_val: "",
      active_item: "",
      orderFlag: false,
      cardType: "",
      payType: "",
      initloading: false,
      type: "",
      companyOrderNo: "",
      unpaid_Flag: false,
      dialogVisible: true,
      oldpayData: "",
      initPayData: {},
      localUrl: "",
      wayManufacturer: "",

      days: 0,
      // orderFlag: false,
      mode_payment_pop_flag: false,
      recommend_item: [],
      recommend_active_flag: "",
      experienceCardFlag: false,
      Level_Description_flag: false,
    };
  },
  watch: {
    active_item(newVal, oldVal) {
      console.log(newVal.cycleIsRecommend, oldVal.cycleIsRecommend, 1);
      if ((newVal.cycleIsRecommend == oldVal.cycleIsRecommend) == 1) {
        console.log("没改变");
        this.active_flag = 0;
        console.log(newVal, "------------", oldVal);
      } else if (oldVal == "") {
        console.log("没改变");
        this.active_flag = 0;
      } else {
        console.log("改变了");
        console.log(newVal, "------------", oldVal);
        this.active_flag = 1;
      }
    },
  },
  methods: {
    handleClose() {
      this.Level_Description_flag = false;
    },
    paymentPop() {
      this.mode_payment_pop_flag = true;
    },
    closeModePay() {
      this.mode_payment_pop_flag = false;
    },
    copyShare() {
      var clipboard = new Clipboard(".share_btn");
      clipboard.on("success", () => {
        // console.log("复制成功");
        this.$notify({
          title: this.$t("lang.alert.succeed"),
          type: "success",
          message: this.$t("lang.Sharing_friends_tit"),
        });
        this.experienceCardFlag = false;

        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    selectRecommend() {
      console.log(
        this.recommend_item[0].cycleId,
        "cycleId---------------------"
      );
      this.active_item = "";
      this.recommend_active_flag = this.recommend_item[0];
    },
    // 初始化数据
    getinitusers() {
      this.initloading = true;
      // if (this.type != "pc") {
      let ran = navigator.userAgent;
      let isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
      let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        // 安卓代码块
        if (!this.$route.query.Authorization) {
          this.initPayData = JSON.parse(window.Android.getPayIntoStr());
          this.getinitusersData();
        }
      }
      if (isIOS) {
        // ios代码块
        if (!this.$route.query.Authorization) {
          this.initPayData = JSON.parse(window.prompt("getPayIntoStr"));
          this.getinitusersData();
        }
      }

      if (this.$route.query.Authorization) {
        localStorage.setItem("token", this.$route.query.Authorization);
        this.$api.getinituser().then((res) => {
          this.initPayData = res.data.data;
          this.getinitusersData();
        });
      }
      console.log(this.initPayData, "终端返回的数据----init");
    },
    getinitusersData() {
      if (this.initPayData) {
        // this.payType = "card";
        this.recommend_item = this.initPayData.cycle.filter((item) => {
          return item.cycleIsRecommend === "1";
        });
        // this.recommend_active_flag == this.recommend_item[0];
        this.recommend_item.forEach((item) => {
          if (item.cycleIsRecommend == "1") {
            if (this.userData.totalDays <= 0) {
              this.active_item = item;
            } else {
              this.active_item = "";
              // if (this.recommend_active_flag == "") {
              this.recommend_active_flag = item;
              // }
            }
          }
        });
        if (this.payType == "") {
          this.payType = "other";
        }
        if (this.wayManufacturer == "") {
          this.wayManufacturer = "airwallex";
        }
        this.localUrl = this.initPayData.local;
        this.userData = this.initPayData.user;
        if (this.userData.vipEndTime) {
          var time = new Date().getTime();
          this.days =
            Number(this.userData.vipEndTime - time) / 1000 / 60 / 60 > 1
              ? Math.floor(
                  Number(this.userData.vipEndTime - time) / 1000 / 60 / 60 / 24
                )
              : Number(this.userData.vipEndTime - time) / 1000 / 60 / 60 > 0
              ? 0.9
              : 0;
          console.log(this.days);
        }
        localStorage.setItem("userData", JSON.stringify(this.initPayData.user));
        // this.cycle = this.initPayData.cycle;
        this.cycle = this.initPayData.cycle.filter((item) => {
          return item.cycleIsRecommend === "0";
        });
        this.cycle.forEach((item) => {
          // if (item.cycleIsRecommend == "1") {
          //   this.active_item = item;
          // }
          if (item.payCycle == 1) {
            if (this.userData.totalDays <= 0) {
              this.active_item = item;
            } else {
              this.active_item = "";
              // this.recommend_active_flag = item;
            }
          }
        });
        this.way = this.initPayData.way.filter((item) => {
          return item.wayType != "card" && item.wayType != "other";
        });
        let cardType = this.initPayData.way.filter((item) => {
          return item.wayType == "card" || item.wayType == "other";
        });
        this.cardType = cardType[0];
        if (
          this.initPayData.oldPay != undefined &&
          this.initPayData.oldPay != ""
        ) {
          if (this.initPayData.oldPay.companyOrderNo != "") {
            console.log("1111111111111");
            this.unpaid_Flag = true;
            this.oldpayData = this.initPayData.oldPay;
            let time = new Date().getTime();
            setTimeout(() => {
              console.log("时间到了--取消支付");
              this.paycancel();
            }, Number(this.oldpayData.endTime - time));
          }
        }
        this.initloading = false;
        if (
          this.$route.query.token != undefined &&
          this.$route.query.PayerID != undefined
        ) {
          // paypal支付
          this.paypalreturn();
        } else {
          if (this.$route.query.isCancel == "true") {
            // 取消支付
            this.checkcancel();
          } else if (
            this.$route.query["?orderNo"] != undefined ||
            this.$route.query["orderNo"] != undefined
          ) {
            // 支付宝/信用卡支付支付
            this.checkreturn();
          }
        }
        this.equipmentChaneg("clear");
      } else {
        this.initloading = false;
      }
    },
    // 选择支付类型
    getpayment(item) {
      this.wayManufacturer = item.wayManufacturer;
      this.payType = item.wayType;
    },
    // 选择支付套餐
    setmealChange(item) {
      console.log(item.cycleId, "cycleId===============");
      this.active_item = item;
      this.recommend_active_flag = "";
    },
    // 创建订单
    submitOrder() {
      // this.getinitusers();
      this.$api.getinituser().then((res) => {
        if (res.data.code == "0") {
          this.initPayData = res.data.data;
          // this.oldpayData=
          if (res.data.data.oldPay) {
            // this.oldpayData = res.data.data.oldPay;
            if (
              this.initPayData.oldPay != undefined &&
              this.initPayData.oldPay != ""
            ) {
              if (this.initPayData.oldPay.companyOrderNo != "") {
                this.unpaid_Flag = true;
                this.oldpayData = res.data.data.oldPay;
                return false;
              }
            }
          }
        }
      });
      console.log(
        this.initPayData.oldPay != undefined || this.initPayData.oldPay != ""
      );
      // if (
      //   this.initPayData.oldPay != undefined &&
      //   this.initPayData.oldPay != ""
      // ) {
      //   if (
      //     this.initPayData.oldPay.companyOrderNo != undefined &&
      //     this.initPayData.oldPay.companyOrderNo != ""
      //   ) {
      //     console.log("待支付");
      //     return false;
      //   }
      //   return false;
      // }
      if (
        this.active_item.cycleTotalPrice == undefined &&
        this.recommend_active_flag.cycleTotalPrice == undefined
      ) {
        this.$notify({
          title: this.$t("lang.alert.warning"),
          message: this.$t("lang.alert.setMeal"),
          type: "warning",
        });
      } else if (this.payType == "") {
        this.$notify({
          title: this.$t("lang.alert.warning"),
          message: this.$t("lang.alert.payment"),
          type: "warning",
        });
      } else {
        this.initloading = true;
        this.$api
          .createOrder({
            cycleId:
              this.active_item.cycleId || this.recommend_active_flag.cycleId,
            payType: this.payType,
            // successURL: "https://payment.hyperlinkvpn.com/#/memberCenter",
            successURL: this.localUrl,
            wayManufacturer: this.wayManufacturer,
          })
          .then((res) => {
            console.log(res, this.payType);
            if (res.data.code == "0") {
              if (this.wayManufacturer == "airwallex") {
                if (res.data.data.intentsId && res.data.data.secret) {
                  loadAirwallex({
                    env: "prod",
                  }).then(() => {
                    redirectToCheckout({
                      // Which env('staging' | 'demo' | 'prod') you would like to integrate with
                      intent_id: res.data.data.intentsId,
                      client_secret: res.data.data.secret,
                      currency: "USD",
                    });
                  });
                }
              } else {
                if (this.payType == "card") {
                  if (res.data.data.redirectUrl) {
                    window.location.href = res.data.data.redirectUrl;
                  } else {
                    this.initloading = false;
                    this.$notify({
                      message: this.$t("lang.alert.not_return"),
                      type: "danger",
                    });
                  }
                } else if (this.payType == "alipay") {
                  this.companyOrderNo = res.data.data.param;
                  // this.alipayReturn();
                } else {
                  if (res.data.data.payPalUrl) {
                    window.location.href = res.data.data.payPalUrl;
                  } else {
                    this.initloading = false;
                    this.$notify.error({
                      title: this.$t("lang.alert.Error"),
                      message: this.$t("lang.alert.not_return"),
                    });
                  }
                }
              }
              this.initloading = false;
            } else if (res.data.code == "9003") {
              this.$notify({
                title: this.$t("lang.alert.warning"),
                message: res.data.message,
                type: "warning",
              });
              this.mode_payment_pop_flag = false;
              this.initloading = false;
              this.unpaid_Flag = false;
              // res.data.message
            } else if (res.data.code == "9001") {
              this.mode_payment_pop_flag = false;
              this.initloading = false;
              this.unpaid_Flag = true;
            }
          })
          .catch(() => {
            this.$notify.error({
              title: this.$t("lang.alert.Error"),
              message: this.$t("lang.alert.network_anomaly"),
            });
            this.initloading = false;
          });
        // }
        // }
      }
    },
    alipayReturn() {
      this.$axios.post("/api/", this.companyOrderNo).then((res) => {
        console.log(res);
      });
    },
    // 回調安卓/ios設備方法
    equipmentChaneg(data) {
      let ran = navigator.userAgent;
      let isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
      let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        // 安卓代码块
        if (data == "clear") {
          if (!this.$route.query.Authorization) {
            window.Android.hideProgress();
          }
        } else {
          if (!this.$route.query.Authorization) {
            window.Android.updateVipEndTime(data);
          }
        }
      }
      if (isIOS) {
        // ios代码块
        if (data == "clear") {
          if (!this.$route.query.Authorization) {
            window.prompt("hideProgress");
          }
        } else {
          if (!this.$route.query.Authorization) {
            window.prompt("updateVipEndTime", data);
          }
        }
      }
    },
    // paypal支付
    paypalreturn() {
      this.initloading = true;
      // 支付成功
      let time = setInterval(() => {
        this.$api
          .paypalreturn({
            companyCode: this.$route.query.token,
            companyPayId: this.$route.query.PayerID,
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.equipmentChaneg(res.data.data);
              location.href = "#/succeed";
              // this.initloading = false;
              clearInterval(time);
            }
          })
          .catch(() => {
            // this.initloading = false;
            clearInterval(time);
          });
      }, 2000);
    },
    // 支付宝/信用卡支付
    checkreturn() {
      this.initloading = true;
      // 支付成功
      let time = setInterval(() => {
        this.$api
          .checkreturn({
            orderNo:
              this.$route.query["?orderNo"] || this.$route.query["orderNo"],
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.equipmentChaneg(res.data.data);
              location.href = "#/succeed";
              clearInterval(time);
              // this.initloading = false;
            }
          })
          .catch(() => {
            // this.initloading = false;
            clearInterval(time);
          });
      }, 2000);
    },
    // 取消支付
    checkcancel() {
      this.initloading = true;
      this.$api
        .checkcancel({
          orderNo: this.$route.query["?orderNo"],
        })
        .then((res) => {
          if (res.data.code == "0") {
            location.href = this.localUrl;
            this.unpaid_Flag = false;
            // this.$message({
            //   showClose: true,
            //   message: res.data.message,
            //   type: "warning",
            // });
            // this.$notify({
            //   title: this.$t("lang.alert.succeed"),
            //   message: this.$t("lang.alert.Cancel_Successful"),
            //   type: "success",
            // });
            this.initloading = false;
          }
        })
        .catch(() => {
          this.initloading = false;
        });
    },
    paycancel() {
      this.initloading = true;
      this.$api.paycancel({ orderNo: this.oldpayData.orderNo }).then((res) => {
        console.log(res);
        if (res.data.code == "0") {
          this.initPayData.oldPay = "";
          this.oldpayData = "";
          // this.lo
          this.unpaid_Flag = false;

          this.initloading = false;
        }
      });
    },
    payagain() {
      if (this.oldpayData.wayManufacturer == "airwallex") {
        if (this.oldpayData.companyOrderNo && this.oldpayData.payUrl) {
          loadAirwallex({
            env: "prod",
          }).then(() => {
            redirectToCheckout({
              // Which env('staging' | 'demo' | 'prod') you would like to integrate with
              intent_id: this.oldpayData.companyOrderNo,
              client_secret: this.oldpayData.payUrl,
              currency: "USD",
            });
          });
        }
      } else if (this.oldpayData.wayManufacturer == "paypal") {
        console.log("1111111111");
        if (this.oldpayData.payUrl) {
          location.href = this.oldpayData.payUrl;
        }
      }
    },
  },

  created() {
    // let ran = navigator.userAgent;
    // let isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
    // let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    // if (isAndroid) {
    //   console.log("isAndroid");
    //   // 安卓代码块
    //   // window.Android.updateVipEndTime();
    //   // window.Android.gotoVipInfo();
    // } else if (isIOS) {
    //   console.log("isIOS");
    //   // ios代码块
    //   // window.prompt("gotoVipInfo");
    // } else {
    //   console.log("window");
    //   this.type = "pc";
    //   console.log(localStorage.getItem("token"));、
    //   localStorage.setItem("type", "pc");
    // }
    let language = this.$route.query.language;
    if (language == "en") {
      this.$i18n.locale = "en-US";
      localStorage.setItem("language", "en-US");
    } else {
      this.$i18n.locale = "zh-CN";
      localStorage.setItem("language", "zh-CN");
    }
    // this.getinitusers();
  },
  mounted() {
    if (this.$route.query.tokenkey != undefined) {
      let tokenkey = this.$route.query.tokenkey;
      localStorage.setItem("token", tokenkey);
      localStorage.setItem("type", "pc");
    }
    // if (this.$route.query.type) {
    //   localStorage.setItem("type", this.$route.query.type);
    // }

    this.getinitusers();
  },
};
</script>

<style lang="less" >
@import url("./index.less");
</style>