<template>
  <div class="left_my">
    <div class="user_box">
      <div class="user_top">
        <div class="user_log">
          <img src="../assets/image/login_h5_log.png" alt="" />
        </div>
        <div class="username">
          <h2>
            <span>{{ user.userName }}</span>
            <img
              v-if="level == 1 && days > 0"
              src="../assets/image/memberCenter/grade/basic_lv1.png"
              alt=""
            />
            <img
              v-if="level == 1 && days <= 0"
              style="width: 50px"
              src="../assets/image/memberCenter/grade/basic_grey_lv1.png"
              alt=""
            />
            <img
              v-if="level == 2 && days > 0"
              src="../assets/image/memberCenter/grade/basic_lv2.png"
              alt=""
            />
            <img
              v-if="level == 2 && days <= 0"
              style="width: 50px"
              src="../assets/image/memberCenter/grade/basic_grey_lv2.png"
              alt=""
            />
            <img
              v-if="level == 3 && days > 0"
              src="../assets/image/memberCenter/grade/basic_lv3.png"
              alt=""
            />
            <img
              v-if="level == 3 && days <= 0"
              style="width: 50px"
              src="../assets/image/memberCenter/grade/basic_grey_lv3.png"
              alt=""
            />
          </h2>
          <p>{{ user.userEmail }}</p>
        </div>
      </div>
      <div class="vip_card">
        <img src="../assets/image/memberCenter/account_VIP.png" alt="" />
      </div>
      <div class="expire_days">
        <!-- <span>{{
          days >= 0
            ? $t("lang.memberCenter.due_date")
            : $t("lang.memberCenter.Validity")
        }}</span>
        <span class="number_days" v-if="$i18n.locale == 'zh-CN'">
          {{
            days > 24
              ? $t("lang.common.surplus") +
                " " +
                Math.floor(days / 24) +
                " " +
                $t("lang.common.day")
              : days > 1
              ? $t("lang.common.surplus") +
                " " +
                Math.floor(days) +
                " " +
                $t("lang.common.hours")
              : days > 0
              ? $t("lang.common.Less_than_an_hour")
              : $t("lang.memberCenter.have_expired")
          }}
        </span>
        <span class="number_days" v-else>{{
          days > 24
            ? Math.floor(days / 24) +
              " " +
              $t("lang.Price.day") +
              " " +
              $t("lang.common.surplus")
            : days > 1
            ? Math.floor(days) +
              " " +
              $t("lang.common.hours") +
              " " +
              $t("lang.common.surplus")
            : days > 0
            ? $t("lang.common.Less_than_an_hour")
            : $t("lang.memberCenter.have_expired")
        }}</span> -->
        <div class="grade_box" v-if="level == 0">
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/bg_Trial.png"
            alt=""
          />
          <img
            v-if="$i18n.locale == 'zh-CN'"
            class="app_lv share_card"
            src="@/assets/image/memberCenter/grade/Share coupon1.png"
            alt=""
          />
          <img
            v-else
            class="app_lv share_card"
            src="@/assets/image/memberCenter/grade/Share coupon.png"
            alt=""
          />
          <!-- <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/Not_recharged.png"
            alt=""
          /> -->
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/bg_grey.png"
            alt=""
          />
          <span class="right_top">{{
            $t("lang.memberCenter.No_Discount")
          }}</span>
          <p class="bot_plan bot_plan_0">
            {{ $t("lang.memberCenter.First_time") }}
          </p>
        </div>
        <!-- 等级1 -->
        <div
          class="grade_box grade_v1"
          v-if="level == 1"
          :class="days <= 0 ? 'ccc' : ''"
        >
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/bg_lv1.png"
            v-if="days > 0"
            alt=""
          />
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/lv1-expire.png"
            v-else
            alt=""
          />
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/lv1.png"
            v-if="days > 0"
            alt=""
          />
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/lv1-grey.png"
            v-else
            alt=""
          />
          <span class="right_top" v-if="days > 0">{{
            $t("lang.memberCenter.discounts")
          }}</span>
          <span class="right_top" v-else>{{
            $t("lang.memberCenter.No_Discount")
          }}</span>
          <p class="bot_plan" @click="LevelDescription">
            {{ $t("lang.memberCenter.repurchase1") }}
            {{ vipProgressNum }}
            <!-- {{ $t("lang.memberCenter.repurchase2") }} -->
            {{
              vipProgressNum > 1
                ? $t("lang.memberCenter.repurchase2")
                : $t("lang.memberCenter.repurchase3")
            }}
            <img
              class="tips"
              src="@/assets/image/memberCenter/grade/lv1_tips.png"
              alt=""
            />
          </p>
          <p class="progress_bar bar" :class="'bar_v1_' + vipProgressNum"></p>
        </div>
        <!-- 等级2 -->
        <div
          class="grade_box grade_v2"
          v-if="level == 2"
          :class="days <= 0 ? 'ccc' : ''"
        >
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/bg_lv2.png"
            v-if="days > 0"
            alt=""
          />
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/lv2-expire.png"
            v-else
            alt=""
          />
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/lv2.png"
            v-if="days > 0"
            alt=""
          />
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/lv2-grey.png"
            v-else
            alt=""
          />
          <span class="right_top" v-if="days > 0">{{
            $t("lang.memberCenter.discounts62")
          }}</span>
          <span class="right_top" v-else>{{
            $t("lang.memberCenter.No_Discount")
          }}</span>

          <p class="bot_plan" @click="LevelDescription">
            {{ $t("lang.memberCenter.repurchase1") }}
            {{ vipProgressNum }}
            <!-- {{ $t("lang.memberCenter.repurchase2") }} -->
            {{
              vipProgressNum > 1
                ? $t("lang.memberCenter.repurchase2")
                : $t("lang.memberCenter.repurchase3")
            }}
            <img
              class="tips"
              src="@/assets/image/memberCenter/grade/lv2_tips.png"
              alt=""
            />
          </p>
          <p class="progress_bar bar" :class="'bar_v2_' + vipProgressNum"></p>
        </div>
        <!-- 等级3 -->
        <div
          class="grade_box grade_v3"
          v-if="level == 3"
          :class="days <= 0 ? 'ccc' : ''"
        >
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/bg_lv3.png"
            v-if="days > 0"
            alt=""
          />
          <img
            class="pc_lv"
            src="@/assets/image/memberCenter/grade/lv3-expire.png"
            v-else
            alt=""
          />
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/lv3.png"
            v-if="days > 0"
            alt=""
          />
          <img
            class="app_lv"
            src="@/assets/image/memberCenter/grade/lv3-grey.png"
            v-else
            alt=""
          />
          <span class="right_top" v-if="days > 0">
            {{ $t("lang.memberCenter.discounts65") }}</span
          >
          <span class="right_top" v-else>{{
            $t("lang.memberCenter.discounts")
          }}</span>

          <p class="bot_plan" @click="LevelDescription">
            {{ $t("lang.memberCenter.Reached_max_level") }}
            <img
              class="tips"
              src="@/assets/image/memberCenter/grade/lv3_tips.png"
              alt=""
            />
          </p>
          <p class="progress_bar bar" :class="'bar_v3_' + vipProgressNum"></p>
        </div>
      </div>
      <div class="app_expire_days">
        <p class="period_validity">
          {{ $t("lang.memberCenter.Validity") }}
          <!-- <span class="number_days">{{
            days >= 0
              ? days + $t("lang.common.day")
              : $t("lang.memberCenter.have_expired")
          }}</span> -->
          <span class="number_days" v-if="$i18n.locale == 'zh-CN'">
            {{
              days > 24
                ? $t("lang.common.surplus") +
                  " " +
                  Math.floor(days / 24) +
                  " " +
                  $t("lang.common.day")
                : days > 1
                ? $t("lang.common.surplus") +
                  " " +
                  Math.floor(days) +
                  " " +
                  $t("lang.common.hours")
                : days > 0
                ? $t("lang.common.Less_than_an_hour")
                : $t("lang.memberCenter.have_expired")
            }}
          </span>
          <span class="number_days" v-else>{{
            days > 24
              ? Math.floor(days / 24) +
                " " +
                $t("lang.Price.day") +
                " " +
                $t("lang.common.surplus")
              : days > 1
              ? Math.floor(days) +
                " " +
                $t("lang.common.hours") +
                " " +
                $t("lang.common.surplus")
              : days > 0
              ? $t("lang.common.Less_than_an_hour")
              : $t("lang.memberCenter.have_expired")
          }}</span>
          <!-- <span v-if="user.vipEndTime > 0">
            {{ $t("lang.common.day") }}
          </span> -->
        </p>
        <!-- <p class="due_date" v-if="user.vipEndTime > 0">
          {{ momentDate(user.vipEndTime) }}
          {{ $t("lang.memberCenter.Expires_on_October") }}
        </p> -->
        <p class="due_date" v-if="days > 0">
          <span v-if="$i18n.locale == 'en-US'">{{
            $t("lang.memberCenter.expire")
          }}</span>
          {{ momentDate(user.vipEndTime) }}
          <span v-if="$i18n.locale == 'zh-CN'">{{
            $t("lang.memberCenter.expire")
          }}</span>
        </p>
      </div>
    </div>

    <div class="member_tabs" v-if="false">
      <ul>
        <li :class="$route.path == '/memberCenter' ? 'active_li' : ''">
          <router-link to="/memberCenter">
            <img
              v-if="$route.path == '/memberCenter'"
              src="../assets/image/memberCenter/web_VIP_on.png"
              alt=""
            />
            <img
              v-if="$route.path == '/equipment'"
              src="../assets/image/memberCenter/web_VIP_off.png"
              alt=""
            />
            {{ $t("lang.memberCenter.RenewMembership") }}
          </router-link>
        </li>
        <li :class="$route.path == '/equipment' ? 'active_li' : ''">
          <router-link to="/equipment">
            <img
              src="../assets/image/memberCenter/web_Equipment_off.png"
              alt=""
              v-if="$route.path == '/memberCenter'"
            />
            <img
              src="../assets/image/memberCenter/web_Equipment_on.png"
              alt=""
              v-if="$route.path == '/equipment'"
            />
            {{ $t("lang.memberCenter.Device_management") }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="out_login" @click="logout" v-if="false">
      <p>
        <img src="../assets/image/memberCenter/out_login.png" alt="" />
        {{ $t("lang.memberCenter.sign_out") }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    userData: Object,
  },
  watch: {
    userData: {
      handler(data) {
        console.log(data, "////////");
        this.user = data;
        this.level = data.level;
        var time = new Date().getTime();
        this.days =
          Number(data.vipEndTime - time) / 1000 / 60 / 60 > 1
            ? Number(data.vipEndTime - time) / 1000 / 60 / 60
            : Number(data.vipEndTime - time) / 1000 / 60 / 60 > 0
            ? 0.9
            : 0;
        console.log(this.days / 24, "  this.days");
        // this.days = Math.floor(
        //   (Number(data.vipEndTime) - time) / 1000 / 60 / 60 / 24
        // );
        this.vipProgressNum = this.getvipProgress();
        console.log(this.vipProgressNum, "1111111111");
      },
    },
  },
  data() {
    return {
      user: this.userData,
      days: 0,
      date: "",
      current_time: "",
      level: 0,
      vipProgressNum: 0,
    };
  },
  methods: {
    LevelDescription() {
      console.log("等級説明");
      this.$parent.Level_Description_flag = true;
    },
    getvipProgress() {
      // 等级
      let numlevel = this.level;
      // 购买的总天数
      let numtotalDays = this.user.totalDays;
      console.log(numlevel, numtotalDays, "=========");
      // 如果是0级 与 3级则是直接归0
      if (numlevel == 0 || numlevel == 3) {
        return 0;
      }
      // 1级升2级则需要60天 / 2级升3级则需要180天
      // 如果是1级的情况下
      if (numlevel == 1) {
        if (numtotalDays <= 0) {
          return 2;
        } else if (numtotalDays < 30) {
          return 2;
        } else {
          return 1;
        }
      }
      // 如果是2级的情况下
      if (numlevel == 2) {
        if (numtotalDays <= 0) {
          return 4;
        } else if (numtotalDays < 90) {
          return 4;
        } else if (numtotalDays < 120) {
          return 3;
        } else if (numtotalDays < 150) {
          return 2;
        } else if (numtotalDays < 180) {
          return 1;
        }
      }

      // 其他登记直接显示0
      return 0;
    },
    momentDate(time) {
      if (this.$i18n.locale == "zh-CN") {
        return moment(time).utcOffset(this.current_time).format("YYYY/MM/DD");
      } else {
        return moment(time).utcOffset(this.current_time).format("MMM DD,YYYY");
      }
    },
    // 退出登录
    logout() {
      setTimeout(() => {
        location.href = "/";
      }, 500);
      localStorage.removeItem("token");
      this.$api
        .logout()
        .then((res) => {
          if (res.data.code == 0) {
            localStorage.removeItem("token");
            this.$notify({
              title: this.$t("lang.alert.succeed"),
              message: this.$t("lang.alert.Exit_successfully"),
              type: "success",
            });
          } else {
            this.$notify({
              title: this.$t("lang.alert.Error"),
              message: res.data.message,
              type: "error",
            });
          }
          // setTimeout(() => {
          //   location.href = "/";
          // }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var offset = new Date() + ""; //将时间格式转为字符串
    // console.log(offset); //  Mon Nov 02 2020 20:57:20 GMT-0600 (北美中部标准时间)
    let asderf = offset.indexOf("GMT");
    let fghdfgd = offset.substring(asderf + 3, asderf + 8);
    // console.log(fghdfgd); //-0600    //拿到这个-0600也就是当前所在时区，然后转时间戳的
    this.current_time = fghdfgd; //给了你定义的一个字段，在拿到数据后使用
  },
};
</script>

<style lang="less" scoped>
// PC端
@media screen and(min-width: 991px) {
  .left_my {
    width: 354px;
    // height: 933px;
    height: calc(100% - 80px);
    // height: 100%;
    background: rgba(250, 248, 246, 1);
    display: inline-block;
    // position: relative;
    position: fixed;

    .user_box {
      padding: 40px 0px 0;

      .vip_card,
      .app_expire_days {
        display: none;
      }

      .user_top {
        display: flex;
        align-items: center;
        padding: 0 20px;
        .user_log {
          margin-right: 12px;

          // padding: 40px 24px 0;
          img {
            width: 66px;
          }
        }

        .username {
          h2 {
            font-size: 18px;
            margin: 0;
            // margin-left: 5px;
            display: flex;
            align-items: center;
            span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 160px;
              display: inline-block;
              margin-right: 10px;
              font-size: 28px;
            }
            img {
              width: 40px;
            }
          }

          p {
            font-size: 16px;
            margin: 0;
            color: #000;
          }
        }
      }

      .expire_days {
        text-align: center;
        padding: 14px 0;
        margin-top: 24px;
        font-size: 18px;
        color: #6d6d6d;
        font-weight: bold;
        display: block;
        width: 100%;
        border-radius: 5px;
        .grade_box {
          width: 100%;
          position: relative;
          color: rgb(64, 72, 94);
          img {
            width: 100%;
          }
          .pc_lv {
            display: block;
          }
          .share_card {
            width: 108px;
            height: 57px;
            position: absolute;
            right: 23px;
            bottom: 16px;
          }
          .app_lv {
            display: none;
          }
          .right_top {
            position: absolute;
            right: 40px;
            top: 25px;
            font-size: 13px;
          }
          .bot_plan {
            position: absolute;
            left: 40px;
            bottom: 20px;
            font-size: 12px;
            font-weight: normal;
            display: flex;
            align-items: center;
            .tips {
              width: 12px;
              height: 12px;
              margin-left: 5px;
            }
          }
          .progress_bar {
            position: absolute;
            bottom: 10px;
            left: 40px;
            width: 46%;
            height: 5px;
            border-radius: 10px;
            box-shadow: 0px 2.2px 2.2px rgba(52, 82, 147, 0.17) inset;
          }
          .bar::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background: rgb(78, 121, 190);
            border-radius: 10px;
          }
          .bar_v1_1::before {
            width: 50%;
          }
          .bar_v1_2::before {
            width: 0%;
          }
          .bar_v2_0::before {
            width: 100%;
          }
          .bar_v2_1::before {
            width: calc(100% - 100% / 4);
          }
          .bar_v2_2::before {
            width: calc(100% - 100% / 4 * 2);
          }
          .bar_v2_3::before {
            width: calc(100% - 100% / 4 * 3);
          }
          .bar_v2_4::before {
            width: calc(100% - 100% / 4 * 4);
          }
        }
        .grade_v1 {
          color: rgb(52, 82, 147);
          .bot_plan {
            color: rgb(106, 124, 157);
          }
        }
        .grade_v2 {
          color: rgb(83, 46, 32);
          .bot_plan {
            color: rgb(83, 46, 32);
          }
          .progress_bar {
            background: rgb(239, 199, 166);
          }
          .bar::before {
            background: rgb(177, 110, 84);
            box-shadow: 0px 2.3px 2.3px rgba(207, 176, 148, 1) inset;
          }
        }
        .grade_v3 {
          .bot_plan,
          .right_top {
            color: rgb(253, 233, 188);
          }
          .bar::before {
            width: 100%;
            background: linear-gradient(
              89.97deg,
              rgba(255, 215, 166, 1) 0.73%,
              rgba(255, 247, 233, 1) 100.48%
            );
          }
        }
        .ccc {
          color: #40485e;
          .bot_plan {
            color: #40485e;
          }
          .right_top {
            color: #40485e;
          }
          .progress_bar {
            background: #ccc;
          }
          .bar::before {
            background: rgba(198, 200, 206, 1);
            box-shadow: 0px 4.4px 4.4px rgba(0, 0, 0, 0.25) inset;
          }
        }
        .number_days {
          color: rgb(247, 164, 3);
        }
      }
    }

    .member_tabs {
      margin-top: 10px;

      ul {
        padding: 0;

        li {
          padding: 16px 30px;
          font-size: 20px;

          a {
            color: #000;
            font-weight: bold;
            display: flex;
            align-items: center;

            img {
              width: 26px;
              margin-right: 8px;
            }
          }
        }

        .active_li {
          background: rgba(255, 245, 224, 1);
          border-left: 5px solid rgba(247, 164, 3, 1);
          padding-left: 25px;
          a {
            color: rgb(247, 164, 3);
          }
        }
      }
    }

    .out_login {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;

      p {
        font-size: 20px;
        padding: 16px 0;
        text-align: center;
        border: 1px solid rgb(247, 164, 3);
        background: rgba(251, 246, 235, 1);
        width: 276px;
        margin: 0 auto;
        border-radius: 5px;

        img {
          width: 24px;
          vertical-align: sub;
        }
      }
    }
  }
}
@media screen and (max-width: 1800px) {
  .left_my {
    min-width: 22vw;
    .user_box {
      .user_top {
        .username {
          h2 span {
            font-size: 1.5vw;
          }
          p {
            font-size: 1vw;
          }
        }
      }
      .expire_days {
        font-size: 1.2vw;
      }
    }
  }
}
//移动端
@media screen and(max-width: 990px) {
  .left_my {
    width: 100%;

    .user_box {
      background: rgb(246, 246, 246);
      background-image: url("../assets/image/memberCenter/account_VIP_bg.png");
      background-repeat: no-repeat;
      background-position-x: right;
      background-size: 60%;
      position: relative;

      .user_top {
        display: flex;
        align-items: center;
        padding: 24px 24px 10px;

        .user_log {
          margin-right: 12px;

          img {
            width: 50px;
          }
        }

        .username {
          h2 {
            margin: 0;

            img {
              // display: none;
              width: 40px;
            }
            span {
              font-size: 20px;
            }
          }

          p {
            margin: 0;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.36);
          }
        }
      }

      .vip_card {
        width: 100%;
        position: relative;
        top: -23px;
        display: none;

        img {
          width: 100%;
        }
      }

      .app_expire_days {
        position: absolute;
        bottom: 30px;
        left: 15px;
        line-height: 33px;
        display: none;

        p {
          margin: 0;
        }

        .period_validity {
          color: #000;
          font-size: 16px;
          font-weight: bold;

          .number_days {
            color: rgb(247, 164, 3);
            margin: 0 5px;
          }
        }

        .due_date {
          color: #666;
          font-size: 14px;
        }
      }

      // .expire_days {
      //   width: 100%;
      //   margin: 24px 0;
      //   // display: none;
      // }
      .expire_days {
        text-align: center;
        padding: 0 0 14px 0;
        margin-top: 0px;
        font-size: 18px;
        color: #6d6d6d;
        font-weight: bold;
        display: block;
        width: 100%;
        border-radius: 5px;
        .grade_box {
          width: 100%;
          position: relative;
          color: rgb(64, 72, 94);
          position: relative;

          img {
            width: 100%;
          }
          .share_card {
            width: 108px;
            height: 57px;
            position: absolute;
            right: 23px;
            bottom: 16px;
          }
          .pc_lv {
            display: none;
          }
          .app_lv {
            display: block;
          }
          .discounts_1 {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
          }
          .right_top {
            position: absolute;
            right: 8%;
            top: 23%;
            font-size: 13px;
          }
          .bot_plan {
            position: absolute;
            left: 30px;
            bottom: 32px;

            font-size: 14px;
            font-weight: normal;
            display: flex;
            align-items: center;
            width: 54%;
            margin: 0;
            .tips {
              width: 12px;
              height: 12px;
              margin-left: 5px;
            }
          }
          .bot_plan_0 {
            bottom: 20%;
          }
          .progress_bar {
            position: absolute;
            bottom: 7px;
            left: 30px;
            width: 46%;
            height: 5px;
            border-radius: 10px;
            box-shadow: 0px 2.2px 2.2px rgba(52, 82, 147, 0.17) inset;
          }
          .bar::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background: rgb(78, 121, 190);
            border-radius: 10px;
          }
          .bar_v1_1::before {
            width: 50%;
          }
          .bar_v1_2::before {
            width: 0%;
          }
          .bar_v2_0::before {
            width: 100%;
          }
          .bar_v2_1::before {
            width: calc(100% - 100% / 4);
          }
          .bar_v2_2::before {
            width: calc(100% - 100% / 4 * 2);
          }
          .bar_v2_3::before {
            width: calc(100% - 100% / 4 * 3);
          }
          .bar_v2_4::before {
            width: calc(100% - 100% / 4 * 4);
          }
        }
        .grade_v1 {
          color: rgb(52, 82, 147);
          .bot_plan {
            color: rgb(106, 124, 157);
          }
        }
        .grade_v2 {
          color: rgb(83, 46, 32);
          .bot_plan {
            color: rgb(83, 46, 32);
          }
          .progress_bar {
            background: rgb(239, 199, 166);
          }
          .bar::before {
            background: rgb(177, 110, 84);
            box-shadow: 0px 2.3px 2.3px rgba(207, 176, 148, 1) inset;
          }
        }
        .grade_v3 {
          .bot_plan {
            color: rgb(30, 32, 48);
          }
          .right_top {
            // color: rgb(253, 233, 188);
            // color: rgb(30, 32, 48);
            color: rgb(253, 233, 188);
          }
          .bar::before {
            width: 100%;
            background: linear-gradient(
              89.97deg,
              rgba(255, 215, 166, 1) 0.73%,
              rgba(255, 247, 233, 1) 100.48%
            );
          }
        }
        .ccc {
          color: #40485e;
          .bot_plan {
            color: #40485e;
          }
          .right_top {
            color: #40485e;
          }
          .progress_bar {
            background: #ccc;
          }
          .bar::before {
            background: rgba(198, 200, 206, 1);
            box-shadow: 0px 4.4px 4.4px rgba(0, 0, 0, 0.25) inset;
          }
        }
        .number_days {
          color: rgb(247, 164, 3);
        }
      }
    }

    .member_tabs,
    .out_login {
      display: none;
    }
  }
}
@media screen and(max-width: 320px) {
  .left_my .user_box .expire_days .grade_box .bot_plan {
    bottom: 29px;
  }
  .left_my .user_box .expire_days .grade_box .progress_bar {
    bottom: 3px;
  }
  .left_my .user_box .expire_days .grade_box .bot_plan_0 {
    bottom: 20px;
    left: 12px;
  }
  .left_my .user_box .expire_days .grade_box .share_card {
    width: 90px;
    height: auto;
  }
}
</style>