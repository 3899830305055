export const lang = {
  common: {
    downloadNow: "Download",
    freeDownload: "Download",
    day: 'day',
    month: "month",
    months: "months",
    days: 'days',
    hours: "hours",
    Less_than_an_hour: "Less than 1 hour remaining",
    surplus: "remaining",
    Hot: "Hot"

  },
  alert: {
    setMeal: "Please select order package",
    payment: "Please select the method of payment",
    not_return: "Payment not returned, please contact the relevant person!",
    network_anomaly: "Service is abnormal!",
    succeed: 'Succeed',
    Error: 'Error',
    warning: 'Warning',
    Cancel_Successful: 'Cancel Successful',

  },
  Price: {
    myPrice: "Price",
    day: "days",
    month: "Month",
    months: "Months",
    just: "just",
    save: "Save",
    preday: "pre day",
    premonth: "pre month",
    guarantee: "7-day money-back guarantee.",
  },
  memberCenter: {
    RenewMembership: 'Renew membership',
    paymentMethod: 'Payment Method',
    Validity: 'Validity',
    expire: 'Expires on',
    Total_order: 'Total order',
    payment: 'Payment',
    have_expired: 'have expired',
    paymentSuccess: "Payment Succeeded",
    emailTo: "Bill sent to",
    daseven_daysys: "you need 7 days for refund without reason, please apply at ",
    Online_service: "Online service",
    apply_for: "",
    Back_to_Home: "View My Account",
    refund: "Note: 7-day packages do not support 7-day refunds.",
    unpaid: "You have a member order to pay",
    TotalCost: "month VIP member, total fee",
    Cancel: "Cancel",
    Payment: "Payment",


    sign_out: 'Sign Out',
    Device_management: 'Device Management',
    due_date: 'Validity：',
    days_remaining: 'days remaining',
    Expires_on_October: 'Expires on October',
    recommend: 'Hot',
    No_Discount: 'No Discount',
    First_time: "First time subscribe,you will get 2-15Days Vip",
    discounts: '58% off',
    repurchase1: "",
    repurchase2: "months to next level",
    repurchase3: "month to next level",

    discounts62: '62% off',
    discounts65: '65% off',
    Reached_max_level: "Reached max level",
    Member_Price: "Member Price",
    VIP: "VIP Lv",
    VIP2: 'user,',
    VIP3: 'off for you',
    low_price: ' As low as',
    noe_Month_VIP: '1 Month VIP',
    old_user: 'Old user rebate, only available to LV',
    old_user2: '',
    Share_Coupon: 'Share Coupon',
    give_coupon: 'First time subscribe，you will get2-15Days Vip！',
    Total_order_price: 'Total order price',
    Subscribe_VIP: 'Subscribe  VIP',
    oldpayDataDay: 'month VIP member, total fee',
    Save: 'Save',
    My_VIP_Share: 'My VIP Share',
    give_friends: '15 day VIP for friends',
    Share: 'Share',
    Congratulations: 'Congratulations!',
    Get_share: ' Get 2 sharing tickets Can be used in welfare rewards',
    zhang: '',
    share_look: '',
  },
  Sharing_friends_tit: "The pasteboard has been copied, you can share it with your friends!",

};
