export const lang = {
  common: {
    downloadNow: "立即下载",
    freeDownload: "免費下載",
    day: "天",
    month: "月",
    months: "月",
    hours: "小時",
    Less_than_an_hour: "剩餘不足1小時",
    surplus: "剩餘",
    Hot: "推薦",
    days: "天"
  },
  alert: {
    setMeal: "請選擇訂單套餐",
    payment: "請選擇支付方式",
    not_return: "支付未返回,請聯係相關人員！",
    network_anomaly: "服務異常！",
    succeed: '成功',
    Error: '错误',
    warning: '警告',
    Cancel_Successful: '訂單取消成功',
  },
  Price: {
    myPrice: "我們的價格",
    day: "天",
    month: "個月",
    months: "個月",
    just: "只需",
    save: "節省",
    preday: "每天",
    premonth: "每月",
    guarantee: "七天無理由退款",
  },
  memberCenter: {
    RenewMembership: '續費會員',
    paymentMethod: '支付方式',
    Validity: '有效期',
    expire: '到期',
    Total_order: '訂單總額',
    payment: '付款',
    have_expired: '已過期',
    paymentSuccess: "支付成功",
    emailTo: "賬單已發至",
    daseven_daysys: "如需7天無理由退款請在 ",
    Online_service: " 在綫客服 ",
    apply_for: "申請",
    Back_to_Home: "查看我的賬戶",
    refund: "注：7天套餐不支持7天退款。",
    unpaid: "您有筆會員訂單待支付",
    TotalCost: "個月VIP會員，合計費用",
    Cancel: "取消",
    Payment: "支付",


    sign_out: '退出登錄',
    Device_management: '設備管理',
    due_date: '有效期：',
    days_remaining: '天',
    Expires_on_October: '到期',
    recommend: "推薦",
    No_Discount: '暫未獲得優惠',
    First_time: "首次訂閱可領2張15天VIP分享券",
    discounts: '已獲得58%優惠',
    repurchase1: "再購買",
    repurchase2: "個月會員可升級",
    repurchase3: "個月會員可升級",
    discounts62: '已獲得62%優惠',
    discounts65: '已獲得65%優惠',
    Reached_max_level: "已達到最高等級",
    Member_Price: "會員價格",
    VIP: "尊貴的Lv",
    VIP2: '用戶，已為您優惠',
    VIP3: '',
    low_price: '低至',
    noe_Month_VIP: '1個月VIP會員',
    old_user: '老用戶回饋價，僅限LV',
    old_user2: '會員訂閱使用',
    Share_Coupon: '送分享券',
    give_coupon: '首次订阅额外送2张15天VIP好友分享券！',
    Total_order_price: '訂單總價：',
    Subscribe_VIP: '訂閱VIP會員',
    oldpayDataDay: '個月VIP會員，合計費用',
    Save: '已優惠',
    My_VIP_Share: '我的VIP分享券',
    give_friends: '贈送好友15天VIP會員',
    Share: '分享',
    Congratulations: '訂閱成功！',
    Get_share: '恭喜您獲得分享券',
    zhang: '張',
    share_look: '隨後可在福利獎勵中查看使用',




  },
  Sharing_friends_tit: "已復制粘貼板，可以去分享給好友了!",
};
