import axios from "axios"
// axios.defaults.baseURL = "http://192.168.31.249:9999/api/"
const request = axios.create({
    // baseURL: 'https://hyperlink.ltd/v1/'
    // baseURL: 'http://192.168.31.249:9999/'
    // baseURL: 'http://192.168.31.44:9999/'
    // baseURL: 'https://server.hyperlinkvpn.com/v1/'
    baseURL: 'https://api.hyperlink.ltd/main/'
    // baseURL: 'https://api.hyperlinkvpn.com/'
})
// request拦截器
request.interceptors.request.use(
    config => {
        let equipment = "";
        if (localStorage.getItem("token") != undefined) {
            equipment = localStorage.getItem("token")
        } else {
            let ran = navigator.userAgent
            let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
            let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

            if (isAndroid) {
                // 安卓代码块
                equipment = window.Android.getAppToken()
            }
            if (isIOS) {
                // ios代码块
                equipment = window.prompt('getiostoken')
            }
        }
        // equipment = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOjUxMzMsIlVzZXJOYW1lIjoiMTMxMDExMTcwNEBxcS5jb20iLCJJc1RyeSI6ZmFsc2UsImV4cCI6MTY3NTY3ODYwMiwiaWF0IjoxNjczMDg2NjAyLCJuYmYiOjE2NzMwODY2MDJ9.RTIPxnbcYqjFMZUmjRU0EaT9hhOczcUi3uIYOgDS8_g"
        config.headers['Authorization'] = equipment
        config.headers['language'] = localStorage.getItem("language") || "zh-CN"
        return config
    }, error => {
        // Do something with request error
        console.log("请求错误信息为：" + error) // for debug
        return Promise.reject(error)
    })
export default request