import Vue from "vue";
import VueRouter from "vue-router";
import memberCenter from "../pages/memberCenter"
import succeed from "../pages/succeed"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "memberCenter",
    component: memberCenter,
  }, {
    path: "/memberCenter",
    name: "memberCenter",
    component: memberCenter,
  }, {
    path: "/succeed",
    name: "succeed",
    component: succeed,
  }
];

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
