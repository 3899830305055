<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    appSource() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        return "ios";
      } else {
        return "andriod";
      }
    },
  },
  mounted() {
    let equipment = this.appSource();
    localStorage.setItem("equipment", equipment);
  },
};
</script>
<style lang="less"></style>
