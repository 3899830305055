import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/common.less";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import axios from "axios";
Vue.prototype.$axios = axios

import api from './api/index.js';

// 配置请求的根路径
axios.defaults.baseURL = '/api/'

Vue.prototype.$api = api
//引入中英文切换插件vue-i18n
import VueI18n from "vue-i18n";


Vue.use(VueI18n); // 挂载

const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "zh-CN", // 语言标识
  messages: {
    "zh-CN": require("./lang/zh"), // 通过require引入中文语言包
    "en-US": require("./lang/en"), // 通过require引入英文语言包
  },
});
Vue.config.productionTip = false;
import {
  Dialog,
  Button,
  Icon,
  Loading,
  MessageBox,
  Message,
  Notification,
  Popconfirm
} from 'element-ui';
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.use(Dialog);
Vue.use(Popconfirm);
Vue.use(Button);
Vue.use(Icon);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
